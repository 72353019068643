// ./hooks/UseTimingDegreeCalculator.tsx

import { useState } from 'react';

const useTimingDegreeCalculator = () => {
  const [crankshaftTeeth, setCrankshaftTeeth] = useState<number | null>(null);
  const [camshaftTeeth, setCamshaftTeeth] = useState<number | null>(null);
  const [crankshaftToothDegrees, setCrankshaftToothDegrees] = useState<number | null>(null);
  const [camshaftToothDegrees, setCamshaftToothDegrees] = useState<number | null>(null);

  const updateCamshaftFromCrankshaft = () => {
    if (crankshaftTeeth !== null && camshaftTeeth === null) {
      setCamshaftTeeth(crankshaftTeeth * 2);
    }
  };

  const updateCrankshaftFromCamshaft = () => {
    if (camshaftTeeth !== null && crankshaftTeeth === null) {
      setCrankshaftTeeth(camshaftTeeth / 2);
    }
  };

  const calculateDegrees = () => {
    if (crankshaftTeeth) {
      setCrankshaftToothDegrees(360 / crankshaftTeeth);
    }
    if (camshaftTeeth) {
      setCamshaftToothDegrees(180 / camshaftTeeth);
    }
  };

  return {
    crankshaftTeeth,
    camshaftTeeth,
    setCrankshaftTeeth,
    setCamshaftTeeth,
    updateCrankshaftFromCamshaft,
    updateCamshaftFromCrankshaft,
    calculateDegrees,
    crankshaftToothDegrees,
    camshaftToothDegrees,
  };
};

export default useTimingDegreeCalculator;
