// ./components/TimingDegreeCalculator.tsx

import React from 'react';
import useTimingDegreeCalculator from '../hooks/UseTimingDegreeCalculator';
import { Button, FormControl, TextField, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import useAnalyticsEventTracker from '../hooks/UseAnalyticsEventTracker';

const TimingDegreeCalculator = () => {
  const {
    crankshaftTeeth,
    camshaftTeeth,
    setCrankshaftTeeth,
    setCamshaftTeeth,
    updateCrankshaftFromCamshaft,
    updateCamshaftFromCrankshaft,
    calculateDegrees,
    crankshaftToothDegrees,
    camshaftToothDegrees,
  } = useTimingDegreeCalculator();

  const gaEventTracker = useAnalyticsEventTracker('TimingDegreeCalculator');
  gaEventTracker('pageLoad');
  
  const handleCalculate = () => {
    gaEventTracker('calculateDegrees');
    calculateDegrees();
  };

  return (
    <div className="App">
      <div>Timing Degree Calculator</div>
      <p>
        Enter either Crankshaft Teeth or Camshaft Teeth, and Click Calculate.<br />
        Crankshaft Tooth Degrees =  360 / Crankshaft Tooth Count<br/>
        Camshaft Tooth Degrees =  180 / Camshaft Tooth Count<br/>
      </p>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          type="number"
          value={crankshaftTeeth ?? ''}
          onChange={e => setCrankshaftTeeth(Number.parseFloat(e.target.value))}
          placeholder="Crankshaft Teeth"
          label="Crankshaft Teeth"
          onBlur={updateCamshaftFromCrankshaft}
        />
      </FormControl><br/>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          type="number"
          value={camshaftTeeth ?? ''}
          onChange={e => setCamshaftTeeth(Number.parseFloat(e.target.value))}
          placeholder="Camshaft Teeth"
          label="Camshaft Teeth"
          onBlur={updateCrankshaftFromCamshaft}
        />
      </FormControl><br/>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <Button
          variant="outlined"
          color="primary"
          aria-label="Calculate Degrees"
          size="large"
          startIcon={<CheckIcon fontSize="inherit" />}
          onClick={handleCalculate}
        >
          Calculate Degrees
        </Button>
      </FormControl><br/>
      {crankshaftToothDegrees !== null && (
        <Typography variant="body1">
          Crankshaft Tooth Degrees: {crankshaftToothDegrees}°
        </Typography>
      )}
      {camshaftToothDegrees !== null && (
        <Typography variant="body1">
          Camshaft Tooth Degrees: {camshaftToothDegrees}°
        </Typography>
      )}
    </div>
  );
};

export default TimingDegreeCalculator;
